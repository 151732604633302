import { Timer } from '@slideslive/fuse-kit/modules';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['item'];
  }

  static get values() {
    return {
      delay: {
        type: Number,
        default: 1000,
      },
    };
  }

  static get outlets() {
    return ['marketing--slide-show'];
  }

  initialize() {
    this.timer = new Timer(this.delayValue, this.nextItem.bind(this), { repeat: true });
    this.currentIndex = 0;
  }

  connect() {
    this.initializeItems();
  }

  disconnect() {
    this.timer.stop();
    this.currentIndex = 0;
  }

  initializeItems() {
    for (let i = 0; i < this.itemTargets.length; i++) {
      const target = this.itemTargets[i];

      target.hidden = i !== this.currentIndex;
    }
  }

  pauseFromEvent({ pointerType }) {
    if (pointerType !== 'mouse') return;

    this.pause();
  }

  resumeFromEvent({ pointerType }) {
    if (pointerType !== 'mouse') return;

    this.resume();
  }

  toggleFromEvent({ pointerType }) {
    if (pointerType === 'mouse') return;

    this.toggle();
  }

  pause() {
    if (this.timer.state !== 'started') return;

    this.timer.pause();
    this.element.classList.remove('playing');
  }

  resume() {
    if (!['initialized', 'paused'].includes(this.timer.state)) return;

    if (this.timer.state === 'initialized') {
      this.eagerLoadImages();
    }

    if (this.timer.state === 'initialized') {
      this.timer.start();
    } else {
      this.timer.resume();
    }

    this.element.classList.add('playing');
    this.pauseOtherSlideShows();
  }

  toggle() {
    if (this.timer.state === 'initialized') {
      this.eagerLoadImages();
    }

    this.timer.toggle();
    this.element.classList.toggle('playing', this.timer.state === 'started');

    if (this.timer.state === 'started') {
      this.pauseOtherSlideShows();
    }
  }

  eagerLoadImages() {
    for (const item of this.itemTargets) {
      const lazyImages = item.querySelectorAll('img[loading=lazy]');

      for (const img of lazyImages) {
        img.loading = 'eager';
      }
    }
  }

  pauseOtherSlideShows() {
    for (const outlet of this.marketingSlideShowOutlets) {
      if (outlet === this) continue;

      outlet.pause();
    }
  }

  nextItem() {
    const oldIndex = this.currentIndex;

    this.currentIndex = (this.currentIndex + 1) % this.itemTargets.length;

    this.itemTargets[this.currentIndex].hidden = false;
    this.itemTargets[oldIndex].hidden = true;
  }

  get nextIndex() {
    return (this.currentIndex + 1) % this.itemTargets.length;
  }
}
