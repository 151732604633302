import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['captcha'];
  }

  async interceptSubmissionForCaptcha(event) {
    if (!this.hasCaptchaTarget) return;

    const { resume, cancel } = event.detail.intercept();
    const captcha = this.findControllerOnElement(this.captchaTarget);
    captcha
      .executeInvisibleCaptcha()
      .then((args) => {
        event.detail.fetchOptions.body.append('g-recaptcha-response', args);
        resume();
      })
      .catch(() => cancel());
  }
}
