import { createCookie, readCookie } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static values = {
    openDelaySeconds: {
      type: Number,
      default: 30,
    },
    closedCookieExpiresInMinutes: {
      type: Number,
      default: 60 * 24 * 30,
    },
  };

  initialize() {
    this.openTimeout = null;
  }

  connect() {
    if (this.isTurboPreview) return;

    this.scheduleOpen();
  }

  disconnect() {
    if (this.openTimeout) {
      clearTimeout(this.openTimeout);
    }

    this.openTimeout = null;
  }

  scheduleOpen() {
    if (readCookie(this.cookieName)) return;

    this.openTimeout = setTimeout(() => {
      this.dispatchOnWindow('open', {
        prefix: 'modal',
        detail: { name: this.modalName },
      });
    }, this.openDelaySecondsValue * 1000);
  }

  setClosedCookie() {
    createCookie(this.cookieName, true, this.closedCookieExpiresInMinutesValue);
  }

  get cookieName() {
    return `${this.identifier}-closed`;
  }

  get modalName() {
    return this.element.dataset.modalName;
  }
}
