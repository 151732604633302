import { ajax, toParamsQueryString } from '@slideslive/fuse-kit/utils';
import 'modules/polyfills/other/location_origin';

import slidesLiveEmbedHost from './slideslive_embed_host';

const RECENT_EMBED_COUNT_LIMIT = 5;
const RECENT_EMBED_COUNT_RESET_TIMEOUT = 60000;

const props = {
  host: null,
  initialized: false,
  initializing: false,
  queue: [],
  userUuid: null,
};

export default class SlidesLiveEmbedInitializer {
  static initialize() {
    props.initializing = true;

    const params = {
      embed_parent_url: window.location.href,
      embed_origin: window.location.origin,
    };

    const host = slidesLiveEmbedHost(props.host);
    const url = `${host}/embed/init?${toParamsQueryString(params)}`;

    ajax('GET', url, undefined, {
      noXRequestedWithHeader: true,
      noAcceptHeader: true,
      withCredentials: true,
      success: (request) => this.initializeSuccess(request),
      error: (request) => this.initializeError(request),
    });
  }

  static initializeSuccess(request) {
    const response = JSON.parse(request.responseText);

    props.embedInitToken = response.embed_init_token;
    props.userUuid = response.user_uuid;
    props.initialized = true;

    for (const callback of props.queue) {
      callback(props.userUuid, props.embedInitToken);
    }

    props.queue = null;
  }

  static initializeError(request) {
    console.warn('SlidesLive Embed Initializer: failed.', request);
  }

  static load(host, callback) {
    if (!props.initialized && !props.initializing) {
      props.host = host;

      this.initialize();
    }

    if (!window._slidesLiveEmbedTotalEmbedCount) {
      window._slidesLiveEmbedTotalEmbedCount = 0;
    }

    if (!window._slidesLiveEmbedRecentEmbedCount) {
      window._slidesLiveEmbedRecentEmbedCount = 0;
    }

    window._slidesLiveEmbedTotalEmbedCount += 1;
    window._slidesLiveEmbedRecentEmbedCount += 1;

    let loadDelay = 0;
    let disableAutoLoad = false;
    if (window._slidesLiveEmbedRecentEmbedCount > RECENT_EMBED_COUNT_LIMIT) {
      loadDelay = (window._slidesLiveEmbedRecentEmbedCount / RECENT_EMBED_COUNT_LIMIT) * 300;
      loadDelay += Math.random() * 3000;
      disableAutoLoad = true;
    }

    if (window._slidesLiveEmbedTotalEmbedCount > RECENT_EMBED_COUNT_LIMIT) {
      console.warn(
        `High number of SlidesLive embeds (> ${RECENT_EMBED_COUNT_LIMIT}) loaded recently has been detected on this page. Videos might not work properly. Report this to this page's owner.`,
        'Total embed count:',
        window._slidesLiveEmbedTotalEmbedCount,
        'Recent embed count:',
        window._slidesLiveEmbedRecentEmbedCount,
        'Load delay:',
        loadDelay,
        'Autoload disabled:',
        disableAutoLoad,
      );
    }

    if (window._slidesLiveEmbedRecentEmbedCountResetTimeout) {
      clearTimeout(window._slidesLiveEmbedRecentEmbedCountResetTimeout);
      window._slidesLiveEmbedRecentEmbedCountResetTimeout = null;
    }

    window._slidesLiveEmbedRecentEmbedCountResetTimeout = setTimeout(() => {
      window._slidesLiveEmbedRecentEmbedCount = 0;
    }, RECENT_EMBED_COUNT_RESET_TIMEOUT);

    const callbackWithDelay = (userUuid, embedInitToken) => {
      if (loadDelay > 0) {
        setTimeout(() => callback(userUuid, embedInitToken, { disableAutoLoad }), loadDelay);
      } else {
        callback(userUuid, embedInitToken, { disableAutoLoad });
      }
    };

    if (props.initialized) {
      callbackWithDelay(props.userUuid, props.embedInitToken);
    } else {
      props.queue.push(callbackWithDelay);
    }
  }
}
