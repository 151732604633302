import { isNaN, isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';
import ApplicationController from 'modules/application_controller';

export default class extends ApplicationController {
  static get targets() {
    return ['days', 'data', 'orderType', 'productType', 'startDate', 'startTime', 'endDate', 'endTime', 'roomCount'];
  }

  updateAll() {
    this.updateStart();
    this.updateEnd();
    this.updateProductType();
    this.updateDays();
    this.updatePrices();
  }

  update() {
    this.updateDays();
    this.updatePrices();
  }

  updateStart() {
    this.startDateTarget.dataset.future = this.today < this.startDate;
    this.endDateTarget.dataset.invalid =
      this.startDate > this.endDate || (this.startDate === this.endDate && this.startTime >= this.endTime);
  }

  updateEnd() {
    this.endDateTarget.dataset.future = this.today < this.endDate;
    this.endDateTarget.dataset.invalid =
      this.startDate > this.endDate || (this.startDate === this.endDate && this.startTime >= this.endTime);
  }

  updateProductType() {
    if (this.productType === 'virtual') {
      this.orderTypeTarget.value = this.productTypeTarget.options[this.productTypeTarget.selectedIndex].dataset.type;
    } else {
      this.orderTypeTarget.value = 'conference';
    }
  }

  updateDays() {
    const days = this.days;
    const startDate = this.startDate;
    const endDate = this.endDate;
    let startTimeInMinutes = this.startTime.split(':');
    startTimeInMinutes = startTimeInMinutes[0] * 60 + startTimeInMinutes[1] * 1;
    let endTimeInMinutes = this.endTime.split(':');
    endTimeInMinutes = endTimeInMinutes[0] * 60 + endTimeInMinutes[1] * 1;

    const recordingDays = this.daysCount(startDate, endDate);
    const hourBreakpoints = this.hourBreakpoints(startTimeInMinutes, endTimeInMinutes);
    const defaultTimes = {
      from: '09:00',
      to: '17:00',
    };

    days.start = {
      date: startDate,
      time: this.startTime,
    };
    days.end = {
      date: endDate,
      time: this.endTime,
    };

    if (recordingDays === 1) {
      days.items = [
        {
          from: this.startTime,
          to: this.endTime,
        },
      ];
    } else {
      for (let i = 0; i < recordingDays; i++) {
        if (i > days.items.length - 1) {
          days.items.push({ ...defaultTimes });
        }

        if (i === 0) {
          const from = this.startTime;
          let to = defaultTimes.to;
          let toTimeInMinutes = to.split(':');
          toTimeInMinutes = toTimeInMinutes[0] * 60 + toTimeInMinutes[1] * 1;

          if (startTimeInMinutes >= toTimeInMinutes) {
            to = hourBreakpoints.to.val;
          }

          this.updateItemInDayItemsJSON(days, i, from, to);
        } else if (i === recordingDays - 1) {
          const to = this.endTime;
          let from = defaultTimes.from;
          let fromTimeInMinutes = days.items[i].from.split(':');
          fromTimeInMinutes = fromTimeInMinutes[0] * 60 + fromTimeInMinutes[1] * 1;

          if (endTimeInMinutes <= fromTimeInMinutes) {
            from = hourBreakpoints.from.val;
          }

          this.updateItemInDayItemsJSON(days, i, from, to);
        } else {
          this.updateItemInDayItemsJSON(days, i, defaultTimes.from, defaultTimes.to);
        }
      }
    }

    days.items = days.items.slice(0, recordingDays);

    this.daysTarget.value = JSON.stringify(days);
  }

  updateItemInDayItemsJSON(days, index, from, to) {
    days.items[index] = { from, to };
  }

  daysCount(startDate, endDate) {
    return Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24)) + 1;
  }

  hourBreakpoints(fromTimeInMinutes, toTimeInMinutes) {
    const breakpoints = {
      360: '06:00',
      960: '16:00',
      1260: '21:00',
      1410: '23:30',
    };
    const breakpointsKeys = Object.keys(breakpoints);
    let fromBreakpoint = null;
    let toBreakpoint = null;

    for (let i = 0; i < breakpointsKeys.length; i++) {
      const minutes = breakpointsKeys[i] * 1;
      const val = breakpoints[minutes];

      if (fromTimeInMinutes < minutes && !toBreakpoint) {
        toBreakpoint = {
          val,
          minutes,
        };
      }

      if (toTimeInMinutes > minutes) {
        fromBreakpoint = {
          val,
          minutes,
        };
      }
    }

    return {
      from: fromBreakpoint,
      to: toBreakpoint,
    };
  }

  updatePrices() {
    const data = this.data;
    const prices = this.prices(data);

    data.prices = Object.removeKey(prices, 'surgePrices');

    if (isVariableDefinedNotNull(prices.surgePrices)) {
      data.prices.surge_prices = prices.surgePrices;
    }

    this.dataTarget.value = JSON.stringify(data);

    return data.prices;
  }

  recordingOrderHoursFromDay({ from: startTime, to: endTime }) {
    const start = startTime.split(':');
    const end = endTime.split(':');
    const hours = end[0] * 1 - start[0] * 1;

    return hours + (end[1] * 1 - start[1] * 1) / 60;
  }

  pricePerHours(data, region, hours, track) {
    const trackData = this.productTypeTracksData(data)[track];
    const trackDurationMinutes = hours * 60;
    let priceKey = 'full_day';
    let overtimeFee = 0;

    if (trackDurationMinutes <= 2 * 60) {
      priceKey = 'two_hours';
    } else if (trackDurationMinutes <= 4 * 60) {
      priceKey = 'half_day';
    } else if (trackDurationMinutes > 9 * 60) {
      overtimeFee = trackData.prices.overtime_fee[region.id] || 0;
    }

    let price = trackData.prices[priceKey][region.id];

    if (!isVariableDefinedNotNull(price)) {
      console.warn('Price not found for track', track, 'in region', region);
      price = 0;
    }

    return price + overtimeFee;
  }

  specificCityCoefficient(data) {
    if (!(this.locationId in data.location_coefficients)) {
      return 1;
    }

    return data.location_coefficients[this.locationId];
  }

  locationCoefficient(data) {
    return this.specificCityCoefficient(data);
  }

  timeLapseCoefficient(data, days) {
    const today = this.element.dataset.updatedAt ? new Date(this.element.dataset.updatedAt) : new Date();
    const timeLapse = Math.ceil((new Date(days.start.date) - today) / (1000 * 60 * 60 * 24));

    for (let i = 0; i < data.time_lapse_coefficients.length; i++) {
      const arr = data.time_lapse_coefficients[i];

      if (!isNaN(arr[0])) {
        if (timeLapse < arr[0]) {
          return arr[1];
        }
      } else {
        return arr[1];
      }
    }

    return 1;
  }

  prices(data) {
    const days = this.days;
    const region = this.region(data);

    let surgePrices = null;
    let totalBasic = 0;
    let totalPremium = 0;
    let totalEnterprise = 0;

    const basic = 'basic';
    let premium = 'premium';
    let enterprise = 'enterprise';

    if (this.orderType === 'workshop') {
      premium = 'basic';
      enterprise = 'premium';
    }

    for (let i = 0; i < days.items.length; i++) {
      const h = this.recordingOrderHoursFromDay(days.items[i]);

      if (this.orderType !== 'workshop') {
        totalBasic += this.pricePerHours(data, region, h, basic);
      }

      totalPremium += this.pricePerHours(data, region, h, premium);
      totalEnterprise += this.pricePerHours(data, region, h, enterprise);
    }

    if (this.roomCount > 1) {
      totalBasic *= this.roomCount;
      totalPremium *= this.roomCount;
      totalEnterprise *= this.roomCount;
    }

    if (this.locationCoefficient(data) !== 1) {
      totalBasic *= this.locationCoefficient(data);
      totalPremium *= this.locationCoefficient(data);
      totalEnterprise *= this.locationCoefficient(data);
    }

    if (this.roomCountCoefficient > 0 && gon.locale !== 'cs') {
      totalBasic += this.roomCountCoefficient * 150000;
      totalPremium += this.roomCountCoefficient * 150000;
      totalEnterprise += this.roomCountCoefficient * 150000;
    }

    if (this.timeLapseCoefficient(data, days) > 1) {
      surgePrices = {
        basic: Math.round(totalBasic * this.timeLapseCoefficient(data, days)),
        premium: Math.round(totalPremium * this.timeLapseCoefficient(data, days)),
        enterprise: Math.round(totalEnterprise * this.timeLapseCoefficient(data, days)),
      };
    }

    if (this.coupon) {
      if (!isNaN(this.coupon.value)) {
        totalBasic -= this.coupon.value;
        totalPremium -= this.coupon.value;
        totalEnterprise -= this.coupon.value;

        if (isVariableDefinedNotNull(surgePrices)) {
          surgePrices = {
            basic: surgePrices.basic - this.coupon.value,
            premium: surgePrices.premium - this.coupon.value,
            enterprise: surgePrices.enterprise - this.coupon.value,
          };
        }
      }
    }

    if (totalBasic < 0) {
      totalBasic = 0;

      if (isVariableDefinedNotNull(surgePrices)) {
        surgePrices.basic = 0;
      }
    }

    if (totalPremium < 0) {
      totalPremium = 0;

      if (isVariableDefinedNotNull(surgePrices)) {
        surgePrices.premium = 0;
      }
    }

    if (totalEnterprise < 0) {
      totalEnterprise = 0;

      if (isVariableDefinedNotNull(surgePrices)) {
        surgePrices.enterprise = 0;
      }
    }

    return {
      basic: Math.round(totalBasic),
      premium: Math.round(totalPremium),
      enterprise: Math.round(totalEnterprise),
      surgePrices,
    };
  }

  productTypeTracksData(data) {
    if (!('types' in data.tracks[this.productType])) {
      return data.tracks[this.productType];
    }

    if (!data.tracks[this.productType].types.includes(this.orderType)) {
      console.error('No tracks for:', this.productType, this.orderType);
    }

    return data.tracks[this.productType][this.orderType];
  }

  region(data) {
    return (
      data.regions.find((r) => r.countries.indexOf(this.locationCountry) > -1) ||
      data.regions.find((r) => r.countries.length === 0)
    );
  }

  get days() {
    return JSON.parse(this.daysTarget.value);
  }

  get data() {
    return JSON.parse(this.dataTarget.value);
  }

  get today() {
    return new Date(new Date().setUTCHours(0, 0, 0, 0));
  }

  get startDate() {
    return new Date(this.startDateTarget.value);
  }

  get startTime() {
    return this.startTimeTarget.value;
  }

  get endDate() {
    return new Date(this.endDateTarget.value);
  }

  get endTime() {
    return this.endTimeTarget.value;
  }

  get productType() {
    return this.productTypeTarget.value;
  }

  get orderType() {
    return this.orderTypeTarget.value;
  }

  get roomCount() {
    return parseInt(this.roomCountTarget.value, 10);
  }

  get roomCountCoefficient() {
    return Math.floor(this.roomCount / 3.0);
  }

  get coupon() {
    return null;
  }

  get locationCountry() {
    return null;
  }

  get locationId() {
    return null;
  }
}
