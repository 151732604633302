import { isVariableDefinedNotNull } from '@slideslive/fuse-kit/utils';

function slidesLiveEmbedHost(defaultHost) {
  if (isVariableDefinedNotNull(defaultHost) && defaultHost !== '') {
    return defaultHost;
  }

  return 'https://slideslive.com';
}

export default slidesLiveEmbedHost;
